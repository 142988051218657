import App from '@hexagon/application/App.vue'
import '@hexagon/application/assets/fonts/inter/inter.css'
import '@hexagon/application/assets/fonts/roboto/roboto-condensed.css'
import { createI18n } from '@hexagon/application/i18n/plugin'
import router from '@hexagon/application/router/router'
import { themeSelectorPlugin } from '@hexagon/application/theme/plugin'
import { registerI18nMessages as registerI18nValidationMessages } from '@hexagon/application/validation/register-i18n-messages'
import { createAuth } from '@hexagon/auth/plugin/plugin'
import { notifications } from '@hexagon/notifications/plugin/plugin'
import { useNotifications } from '@hexagon/notifications/use/useNotifications'
import { uiComponents } from '@hexagon/ui/plugin/plugin'
import '@material-design-icons/font'
import { init as initialzeSentry } from '@sentry/vue'
import { VueQueryPlugin } from '@tanstack/vue-query'
import { createHead } from '@vueuse/head'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import { createPinia } from 'pinia'

import { createApp } from 'vue'
// eslint-disable-next-line boundaries/no-unknown
import './index.css'

const app = createApp(App)

initialzeSentry({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  release: import.meta.env.PACKAGE_VERSION,
  logErrors: import.meta.env.DEV
})

createAuth().then(auth => {
  app.use(auth, { router })
     .use(VueQueryPlugin, {
       queryClientConfig: {
         // NOTE: remove as soon as logger is removed from @tanstack/vue-query
         // this is empty, to prevent unnecessary logging from vue-query
         logger: {
           // eslint-disable-next-line @typescript-eslint/no-empty-function
           log: () => {
           },
           // eslint-disable-next-line @typescript-eslint/no-empty-function
           warn: () => {
           },
           // eslint-disable-next-line @typescript-eslint/no-empty-function
           error: () => {
           }
         },
         defaultOptions: {
           queries: {
             staleTime: 60 * 1000,
             retry: (failureCount, error) => {
               // if the user is not allowed to make the request, then do not retry
               // eslint-disable-next-line @typescript-eslint/ban-ts-comment
               // @ts-ignore
               if (error?.status < 500 && error?.status !== 408) {
                 return false
               } else {
                 // 2 retrys are allowed; in total 3 requests at max
                 return failureCount < 3
               }
             },
             onError: (error) => {
               // eslint-disable-next-line @typescript-eslint/ban-ts-comment
               // @ts-ignore
               useNotifications().warning(error?.message || error)
             }
           }
         }
       }
     })
     .use(router)

  const i18n = createI18n()

  app.use(i18n)
     .use(createHead())
     .use(themeSelectorPlugin)
     .use(FloatingVue, { themes: { tooltip: { delay: { show: 0, hide: 200 } } } })
     .use(createI18n)
     .use(createPinia())
     .use(notifications)
     .use(uiComponents)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  registerI18nValidationMessages(i18n)

  app.mount('#mounting-point')
})
