import axios from 'axios'
import { PermissionsModel } from '../models/permissions-model'
import { UserWithPermissionsModel } from '../models/user-with-permissions-model'

export interface GatewaySettings {
  accessToken?: string
  baseUrl: string,
}

interface UserModel {
  email: string,
  id: string,
  name?: string,
}

interface UserProfileModel {
  permissions: string[]
  user: UserModel,
}

export class APIGateway {
  constructor(private settings: GatewaySettings) {
  }

  set accessToken(token: string | undefined) {
    this.settings.accessToken = token
  }

  async getMe(): Promise<UserWithPermissionsModel> {
    return APIGatewayMapper.mapUserProfileToUserWithPermissions((await axios.get<UserProfileModel>('/api/v1/users/me', {
      baseURL: this.settings.baseUrl,
      headers: { 'Authorization': 'Bearer ' + this.settings.accessToken }
    })).data)
  }
}

export class APIGatewayMapper {
  static mapUserProfileToUserWithPermissions(userProfile: UserProfileModel): UserWithPermissionsModel {
    return {
      id: userProfile.user.id,
      email: userProfile.user.email,
      name: userProfile.user.name,
      permissions: APIGatewayMapper.mapPermissionsArrayToPermissionsModel(userProfile.permissions)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static mapPermissionsArrayToPermissionsModel(permissions: string[]): PermissionsModel {
    return {
      // testPermission: permissions.includes('ANYTHING'),
    }
  }
}
