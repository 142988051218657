<template>
  <dw-link-or-span v-if="$theme.isHexagon" class="relative inline-block group cursor-pointer">
    <svg :class="colors.svg" fill="none" height="52" viewBox="0 0 44 52" width="44" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M44 13.6397L44 39.1406L22 51.89L-5.57293e-07 39.1406L-1.67197e-06 13.6397L22.0001 0.890014L44 13.6397ZM42.1005 38.0392L42.1005 14.7408L22.0001 3.09156L1.89945 14.7408L1.89945 38.0392L22 49.6885L42.1005 38.0392Z"
        fill="currentColor"/>

      <path
        class="invisible group-hover:visible"
        d="M19.8985 49.3626L4.17387 40.9662C2.22013 39.923 1 37.8883 1 35.6735V17.9739C1 15.8352 2.13842 13.8581 3.98815 12.7846L19.7106 3.65934C21.5925 2.56705 23.9184 2.57929 25.7887 3.69132L41.0663 12.7746C42.8852 13.8561 44 15.8158 44 17.932V35.7145C44 37.9076 42.8034 39.926 40.8793 40.9784L25.604 49.3339C23.8287 50.3049 21.6835 50.3157 19.8985 49.3626Z"
        fill="currentColor"/>

      <circle v-if="badge" class="text-yellow" cx="10.5" cy="44.39" fill="currentColor" r="6.5"/>
    </svg>

    <div :class="colors.slot" class="absolute inset-0 flex items-center justify-center">
      <slot></slot>
    </div>
  </dw-link-or-span>

  <dw-link-or-span
    v-else
    :class="cn(buttonVariants({ variant: active ? 'active' : 'default' }))"
    active-class="bg-primary-200 border-black/10 hover:!bg-primary-300 apv:bg-apv-green/40 apv:hover:!bg-apv-green/25">
    <span v-if="badge" class="absolute -top-0.5 -right-0.5 h-3.5 w-3.5 rounded-full bg-primary-600 apv:bg-apv-green"></span>

    <span class="block">{{ text }}</span>
    <slot></slot>
  </dw-link-or-span>
</template>

<script lang="ts" setup>
import { cn } from '@hexagon/ui/util'
import { cva } from 'class-variance-authority'
import { computed } from 'vue'

interface Props {
  active?: boolean
  badge?: boolean
  color: 'blue' | 'orange' | 'green'
  text?: string
}

const props = defineProps<Props>()
const colors = computed<Record<'svg' | 'slot', string>>(() => {
  let tuple: Record<'svg' | 'slot', string>
  switch (props.color) {
    case 'blue':
      tuple = { svg: 'text-blue', slot: 'text-blue group-hover:text-white' }
      break
    case 'orange':
      tuple = { svg: 'text-orange', slot: 'text-orange group-hover:text-white' }
      break
    case 'green':
      tuple = { svg: 'text-green', slot: 'text-green group-hover:text-white' }
  }

  return tuple
})

const buttonVariants = cva(
    'relative inline-flex items-center justify-center space-x-1 px-3 h-[40px] text-md rounded-lg font-semibold transition-colors border border-layer2 cursor-pointer',
    {
      variants: {
        variant: {
          default:
              'hover:bg-grey-100 hover:border-grey-400',
          active:
              'bg-primary-200 border-black/10 hover:!bg-primary-300 apv:bg-apv-green/40 apv:hover:!bg-apv-green/25'
        }
      },
      defaultVariants: {
        variant: 'default'
      }
    }
)
</script>
