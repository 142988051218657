import { Router, RouteRecordRaw } from 'vue-router'
import NotFoundPage from '../components/404.vue'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    component: () => Promise.resolve(import('@hexagon/home/home.vue')),
    meta: {
      auth: true
    }
  },
  {
    path: '/intro',
    name: 'intro',
    component: () => Promise.resolve(import('@hexagon/home/intro.vue')),
    meta: {
      auth: 'guest'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => Promise.resolve(import('@hexagon/auth/register.vue')),
    meta: {
      auth: 'guest'
    }
  },
  {
    path: '/login-redirect',
    name: 'login-redirect',
    component: () => Promise.resolve(import('@hexagon/auth/login-redirect.vue')),
    meta: {
      auth: 'guest'
    }
  },
  {
    path: '/calculation-result/:id',
    name: 'exchangers',
    component: () => Promise.resolve(import('@hexagon/exchangers/exchangers.vue')),
    meta: {
      auth: true
    }
  },
  {
    path: '/exchanger/:id',
    name: 'exchanger',
    component: () => Promise.resolve(import('@hexagon/exchangers/exchanger.vue')),
    meta: {
      auth: true
    }
  },
  {
    path: '/configuration',
    name: 'configuration',
    component: () => Promise.resolve(import('@hexagon/configuration/configuration.vue')),
    meta: {
      auth: true
    }
  },
  {
    path: '/unrealistic-configuration',
    name: 'unrealistic-configuration',
    component: () => Promise.resolve(import('@hexagon/configuration/unrealistic-configuration.vue')),
    meta: {
      auth: true
    }
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => Promise.resolve(import('@hexagon/offer/cart.vue')),
    meta: {
      auth: true
    }
  },
  {
    path: '/offer-confirmation',
    name: 'offer-confirmation',
    component: () => Promise.resolve(import('@hexagon/offer/offer-confirmation.vue')),
    meta: {
      auth: true
    }
  },
  {
    path: '/offer-confirmation/customer',
    name: 'offer-confirmation-customer',
    component: () => Promise.resolve(import('@hexagon/offer/customer-edit.vue')),
    meta: {
      auth: true
    }
  },
  {
    path: '/:pathMath(.*)*',
    component: NotFoundPage
  }
]

// components overview only available in dev env
if (import.meta.env.DEV) {
  routes.push({
    path: '/_storybook',
    component: () => Promise.resolve(import('@hexagon/home/_storybook.vue'))
  })
}

// all routes which are called with additional params or query information should be placed inside here
function registerRouterMethods(router: Router) {
  router.resolveIntro = function () {
    return router.resolve({ name: 'intro' })
  }
  router.resolveRegister = function () {
    return router.resolve({ name: 'register' })
  }
  router.resolveHome = function () {
    return router.resolve({ name: 'home' })
  }
  router.replaceHome = function () {
    return router.replace({ name: 'home' })
  }
  router.pushHome = function () {
    return router.push({ name: 'home' })
  }
  router.resolveConfiguration = function (calculationResultId?: string) {
    return router.resolve({ name: 'configuration', query: { 'calculation-result-id': calculationResultId } })
  }
  router.pushUnrealisticConfiguration = function () {
    return router.push({ name: 'unrealistic-configuration' })
  }
  router.pushCalculationResult = function (calculationId: string) {
    return router.push({ name: 'exchangers', params: { id: calculationId } })
  }
  router.resolveCalculationResult = function (calculationId: string) {
    return router.resolve({ name: 'exchangers', params: { id: calculationId } })
  }
  router.pushExchanger = function (id: string) {
    return router.push({ name: 'exchanger', params: { id } })
  }
  router.resolveExchanger = function (id: string) {
    return router.resolve({ name: 'exchanger', params: { id } })
  }
  router.resolveCart = function () {
    return router.resolve({ name: 'cart' })
  }
  router.pushCart = function () {
    return router.push({ name: 'cart' })
  }
  router.resolveOffer = function () {
    return router.resolve({ name: 'offer-confirmation' })
  }
  router.pushOffer = function () {
    return router.push({ name: 'offer-confirmation' })
  }
  router.resolveOfferCustomer = function () {
    return router.resolve({ name: 'offer-confirmation-customer' })
  }
}

export default routes
export {
  registerRouterMethods
}
