<template>
  <span :class="cn(chipVariants({variant}))" class="text-µ">
    <span class="inner-bg block absolute inset-px pointer-events-none -z-10 transition-colors hx-clip-chip"></span>
    <svg
      v-if="variant === 'download'"
      class="mr-1"
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.66675 14H11.3334C12.438 14 13.3334 13.1046 13.3334 12V5.67287C13.3334 5.14244 13.1227 4.63374 12.7476 4.25867L11.0747 2.58579C10.6997 2.21072 10.191 2 9.66054 2H4.66675C3.56218 2 2.66675 2.89543 2.66675 4V8.66667"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"/>
      <path
        d="M13.3334 5.66667H11.0001C10.2637 5.66667 9.66675 5.06971 9.66675 4.33333V2"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"/>
      <path d="M4.66659 10.6667V14.0001" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
      <path d="M6.00008 12.6667L4.66675 14.0001" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
      <path d="M3.33325 12.6667L4.66659 14.0001" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
    </svg>

    <slot></slot>
  </span>
</template>

<script lang="ts" setup>
import { cn } from '@hexagon/ui/util'
import { cva, VariantProps } from 'class-variance-authority'

const chipVariants = cva(
    'h-[22px] inline-flex items-center justify-between relative px-2 text-µ font-semibold border border-transparent rounded-lg hexagon:font-medium hexagon:hx-clip-chip hexagon:px-4 hexagon:border-none hexagon:rounded-none',
    {
      variants: {
        variant: {
          default: 'text-white bg-primary-800 hexagon:bg-blue',
          secondary: 'text-grey-900 bg-white border-layer2 hexagon:bg-layer2 hexagon:[&>.inner-bg]:bg-layer1',
          green: 'text-white bg-paradise-800 hexagon:bg-green',
          'light-green': 'text-paradise-800 bg-paradise-800/10 border-paradise-800/10 hexagon:text-green hexagon:bg-light-green',
          yellow: 'text-[#D9A421] bg-[#FCF1D6] border-[#FCF1D6]',
          orange: 'text-sunrise-700 bg-sunrise-100 border-sunrise-800/10',
          red: 'text-watermelon-800 bg-watermelon-100 border-watermelon-800/10',
          violet: 'text-violet bg-ultraviolet-200 border-violet-800/10',
          download: 'text-primary-800 bg-primary-100 border-primary-200 hover:text-primary-1000 hover:bg-primary-300 hover:border-primary-400'
        }
      },
      defaultVariants: {
        variant: 'default'
      }
    }
)

interface Props {
  variant?: VariantProps<typeof chipVariants>['variant']
}

withDefaults(defineProps<Props>(), {
  variant: 'default'
})
</script>
