// eslint-disable-next-line boundaries/element-types
import { useAuthRedirect } from '@hexagon/auth/use/use-auth-redirect'
import { useGuestUser } from '@hexagon/auth/use/use-guest-user'
import { useNotifications } from '@hexagon/notifications/use/useNotifications'
import { WebStorageStateStore } from 'oidc-client-ts'
import { defineComponent, h, Plugin } from 'vue'
import { Router, useRouter } from 'vue-router'
import { PermissionsModel } from '../models/permissions-model'
import { checkIfRoutingCanBeDone } from '../router/check-if-routing-can-be-done'
import { getDefaultRouteForLoggedInUser } from '../router/get-default-route-for-logged-in-user'
import { initializeUseAuth, useAuth } from '../use/useAuth'
import { Auth } from './auth'

declare module 'vue-router' {
  interface RouteMeta {
    /**
     * @member auth
     * undefined: any authenticated and non authenticated user can access this page
     * true: any authenticated user can access this page
     * 'guest': any non-authenticated user can access this page
     * (keyof Permissions)[]: any user having at least one of the listed permissions
     */
    auth?: true | 'guest' | (keyof PermissionsModel)[]
    layout?: () => Promise<unknown>
  }
}

const BASE_URL = window.location.origin
const CALLBACK_PATH = '/authorization-callback'
let auth: Auth | null = null

/**
 * Initializes Authentication
 * Must be called and awaited before creating Vue instance
 *
 * Note: Since Vue plugins cannot be installed asynchronously, this code must be executed before the Vue instance is created
 */
async function createAuth(): Promise<Plugin> {
  auth = new Auth({
    oidc: {
      authority: import.meta.env.VITE_AUTH_AUTHORITY,
      metadataUrl: import.meta.env.VITE_AUTH_METADATA_URL,
      client_id: import.meta.env.VITE_AUTH_CLIENT_ID,
      redirect_uri: BASE_URL + CALLBACK_PATH,
      scope: 'offline_access openid',
      automaticSilentRenew: false,

      // Store tokens in localStorage in order to stay logged-in in multiple tabs and after reopening the browser
      // Remove this line to stay logged-in in just one tab and get logged out when closing the browser/tab
      userStore: new WebStorageStateStore({ store: window.localStorage })
    },
    gateway: {
      baseUrl: import.meta.env.VITE_API_GATEWAY_URL
    }
  })

  if (window.location.pathname !== CALLBACK_PATH) {
    await auth.initialize()
  }

  initializeUseAuth(auth)

  return authPluginInstaller
}

const authPluginInstaller: Plugin = {
  /**
   * Installs callback route and auth router middleware
   */
  install: (app, options: { router: Router }) => {
    // only add authorization callback if user is currently on that route
    // this route can only be accessed directly after oauth login
    if (window.location.pathname === CALLBACK_PATH) {
      options.router.addRoute({
        path: CALLBACK_PATH,
        component: defineComponent({
          setup() {
            const router = useRouter()
            const auth = useAuth()
            const { redirect } = useAuthRedirect()
            auth.handleCallback().then(() => {
              redirect()
            }).catch(() => {
              useNotifications().error('Login failed. Please try again later.')
              router.replace(router.resolveIntro())
            })
          },
          render() {
            return h('div')
          }
        })
      })
    }

    options.router.beforeEach(async (to, from, next) => {
      const auth = useAuth()

      try {
        const user = useGuestUser().getGuestUserAsModel() ?? auth.user.value
        const routingPermissionResult = checkIfRoutingCanBeDone(to.meta, user)
        const { setRedirectPathOrUri } = useAuthRedirect()
        if (routingPermissionResult) {
          next()
        } else {
          console.warn('routing not permitted', to)
          if (auth.user.value) {
            next({ name: getDefaultRouteForLoggedInUser(auth.user.value) })
          } else {
            setRedirectPathOrUri(to.fullPath)
            next(options.router.resolveIntro())
          }
        }
      } catch (e) {
        next(e as Error)
      }
    })
  }
}

export {
  createAuth
}
